import { Payment } from "types";

export const payments: Payment[] = [
  {
    name: 'payme',
    fullName: 'Payme',
  },
  {
    name: 'transakcje25',
    fullName: 'Transakcje25',
  }
]