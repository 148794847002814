import { Text } from "@chakra-ui/react"

export const NotFound = () => {

  return (
    <Text
      fontSize="3xl"
      color="blue.600"
      alignSelf="center"
      fontWeight="bold"
    >
      Uuups.. Podana strona nie została odnaleziona
    </Text>
  )
}